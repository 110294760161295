<template>
    <BaseModal ref="base_modal" @close="closeModal">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <UserInfoCard :user="user" :interact="false" />

        <form @submit.prevent="updateUser">

            <q-select square filled color="accent"
                use-chips
                multiple
                :label="resource[lang].select_roles"
                v-model="selected_user_roles"
                :options="selectable_user_role_names"
            />

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].update" submit />
                <Button color="white" :label="resource[lang].discard" @click="closeModal" />
            </div>
        </form>
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/user-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import UserInfoCard from '@/components/UserInfoCard'

import { promiseGetRequest, promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        BaseModal,
        Button,
        UserInfoCard
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            edit_user: null,

            user_roles: [],

            selected_user_roles: [],

            resource
        }
    },
    computed: {
        user_role_ids() {
            return this.selected_user_roles.map(role => this.user_roles.find(item => item.name == role).id);
        },
        selectable_user_roles() {
            return this.user_roles.filter(role => {
                for (let item of this.selected_user_roles) {
                    if (role.name == item) {
                        return false;
                    }
                }
                return true;
            });
        },
        selectable_user_role_names() {
            return this.user_roles.filter(role => {
                for (let item of this.selected_user_roles) {
                    if (role.name == item) {
                        return false;
                    }
                }
                return true;
            }).map(role => role.name);
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        async init() {
            this.edit_user = this.user;
            this.selected_user_roles = this.user.user_roles.map(role => role.name);

            this.$store.dispatch('loading', true);
            const response = await this.getUserRoles();
            this.$store.dispatch('loading', false);

            this.user_roles = response.data;
        },

        // Requests
        getUserRoles() {
            return promiseGetRequest('user-roles');
        },
        updateUserRoles() {
            const request_body = { user_role_ids: this.user_role_ids };
            return promisePutRequest(`update-user-roles/${this.edit_user.id}`, request_body);
        },

        // Actions
        closeModal() {
            this.$emit('cancel');
            this.$refs.base_modal.closeModal();
        },
        async updateUser() {
            this.$store.dispatch('loading', true);
            const response = await this.updateUserRoles();
            this.$store.dispatch('loading', false);

            this.$emit('update', response.data.user);
            this.$refs.base_modal.closeModal();
        },
    },
    emits: ['update', 'cancel']
}
</script>

<style lang="scss" scoped>

form {
    margin-block-start: 2rem;
}

form > * + * {
    margin-block-start: 2rem;
}

.actions {
    justify-content: space-between;
}

</style>