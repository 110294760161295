<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />

        <div class="search grid">
            <InputText :label="resource[lang].enter_search_term" v-model="search_term" />
            <Button color="primary" :label="resource[lang].search" @click="search" />
        </div>

        <div v-for="user in users" :key="user.id" class="user grid">
            <label class="modal-heading-2" v-html="user.full_name" />
            <Button stretch color="accent" :label="resource[lang].edit" @click="showUserEditModal(user)" />
        </div>

        <Button v-if="!all_users" class="load-more" color="accent" :label="resource[lang].load_more" @click="loadUsers" />

        <UserModal
            v-if="showUserEditModalFlag"
            :user="edit_user"
            @update="updateUser"
            @cancel="cancelUserEditModal"
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/project-users-resource' 

import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'
import UserModal from '@/components/modals/UserModal'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        InputText,
        UserModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            users: [],
            user_page: 1,
            all_users: true,

            showUserEditModalFlag: false,
            edit_user: null,

            search_term: '',
            applied_search_term: '',

            resource
        }
    },
    created() {
        this.loadUsers();
    },
    methods: {
        // Requests
        getUsersRequest() {
            let route = `get-users?page=${this.user_page}`;
            
            if (this.applied_search_term) {
                route = `${route}&search=${this.applied_search_term}`;
            }

            return promiseGetRequest(route);
        },

        // Actions
        showUserEditModal(user) {
            this.edit_user = user;
            this.showUserEditModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelUserEditModal() {
            this.edit_user = null;
            this.showUserEditModalFlag = false;
        },
        updateUser(user) {
            const index = this.users.findIndex(item => item.id == user.id);

            this.users[index] = user;

            this.edit_user = null;
            this.showUserEditModalFlag = false;
        },
        search() {
            this.applied_search_term = this.search_term;
            this.users = [];
            this.user_page = 1;
            this.loadUsers();
        },

        // Functions
        async loadUsers() {
            this.$store.dispatch('loading', true);
            const response = await this.getUsersRequest();
            this.$store.dispatch('loading', false);

            this.users = [...this.users, ...response.data];

            this.user_page += 1;

            if (response.data.length < 10) {
                this.all_users = true;
            } else {
                this.all_users = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.load-more {
    margin-block-start: 2rem;
}

.user {
    background-color: var(--whit);

    grid-template-columns: 3fr 1fr;

    padding: 1rem;

    label {
        margin: 0;

        height: 100%;

        display: flex;
        align-items: center;
    }
}

.user + .user {
    margin-block-start: 1rem;
}

</style>