const resource = {
    en: {
        heading: 'Users',
        edit: 'Edit',
        load_more: 'load more',
        enter_search_term: 'Enter Search Term',
        search: 'Search',
    },
    de: {
        heading: 'Anwender*innen',
        edit: 'Bearbeiten',
        load_more: 'mehr laden',
        enter_search_term: 'Suchbegriff eingeben',
        search: 'Suchen',
    }
}

export default resource;