const resource = {
    en: {
        name: 'Name',
        email: 'Email Address',
        user_info: 'User Info',
        remove: 'remove',
        show_matrices: 'show matrices'
    },
    de: {
        name: 'Name',
        email: 'Email-Addresse',
        user_info: 'Profil Info',
        remove: 'entfernen',
        show_matrices: 'zeige Matrizen'
    }
}

export default resource;