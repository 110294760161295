<template>
    <div class="user-info">
        <div class="grid">
            <label class="label" v-html="`${resource[lang].name}:`" />
            <label class="value" v-html="user.full_name" />
            <label class="label" v-html="`${resource[lang].email}:`" />
            <label class="value" v-html="user.email" />
        </div>
        <label v-html="resource[lang].user_info" />
        <div class="actions">
            <button v-if="remove" v-html="resource[lang].remove" @click="$emit('remove')" />
            <button v-if="show_matrices" v-html="resource[lang].show_matrices" @click="$emit('show_matrices')" />
        </div>
    </div>
</template>

<script>
import resource from '@/assets/resources/user-info-card-resource'

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        show_matrices: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    emits: ['remove', 'show_matrices']
}
</script>

<style lang="scss" scoped>

.actions {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    & > * {
        background-color: var(--whit);
        border: none;

        cursor: pointer;

        color: var(--accent);
        font-family: var(--standard-font);
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.2;
        text-decoration: underline;
        text-transform: uppercase;
    }

    & > * + * {
        margin-inline-start: 1rem;
    }
}

.actions > *:hover, 
.actions > *:focus-visible {
    font-weight: 500;
    text-decoration: none;
}

.grid {
    column-gap: 1rem;
    row-gap: 0.5rem;
    grid-template-columns: 1fr 2fr;

    label {
        color: var(--blak);

        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 400;
    }
}

.user-info {
    position: relative;

    background-color: var(--whit);
    padding: 1rem;

    & > label {
        position: absolute;
        right: 1rem;
        top: 1rem;

        color: var(--accent);

        font-family: var(--standard-font);
        font-size: 1rem;
        font-weight: 500;

        text-transform: uppercase;
    }
}

@media (max-width: 60rem) {

    .grid {
        display: block;

        * {
            display: block;
        }

        button {
            margin-left: auto;
        }

        button + button {
            margin-top: 0.5rem;
        }

        .label {
            font-size: 0.75rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        .value {
            font-size: 1rem;
        }

        .label {
            margin-block-start: 1rem;
        }
    }
}

@media (max-width: 40rem) {
    .user-info {
        padding-bottom: 4rem;
    }
}

</style>