const resource = {
    en: {
        heading: 'Edit User',
        update: 'Update',
        discard: 'Discard',
        select_roles: 'Select User Roles',
    },
    de: {
        heading: 'Anwender*innen',
        update: 'Aktualisieren',
        discard: 'Abbrechen',
        select_roles: 'Benutzerrollen auswählen'
    }
}

export default resource;